import { createGlobalStyle } from 'styled-components'
import SkWoffRegular from './fonts/Sk-Modernist-Regular.woff'
import SkWoff2Regular from './fonts/Sk-Modernist-Regular.woff2'
import SkWoffMono from './fonts/Sk-Modernist-Mono.woff'
import SkWoff2Mono from './fonts/Sk-Modernist-Mono.woff2'
import SkWoffBold from './fonts/Sk-Modernist-Bold.woff'
import SkWoff2Bold from './fonts/Sk-Modernist-Bold.woff2'

const sk = {
  regular: {
    woff2: SkWoff2Regular,
    woff: SkWoffRegular,
  },
  mono: {
    woff2: SkWoff2Mono,
    woff: SkWoffMono,
  },
  bold: {
    woff2: SkWoff2Bold,
    woff: SkWoffBold,
  },
}

const Fonts = createGlobalStyle`
  @font-face {
    font-family: 'Sk-Modernist';
    src: local('Sk-Modernist'),
    url(${sk.regular.woff2}) format('woff2'),
    url(${sk.regular.woff}) format('woff');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'Sk-Modernist Mono';
    src: local('Sk-Modernist Mono'),
    url(${sk.mono.woff2}) format('woff2'),
    url(${sk.mono.woff}) format('woff');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'Sk-Modernist';
    src: local('Sk-Modernist'),
    url(${sk.bold.woff2}) format('woff2'),
    url(${sk.bold.woff}) format('woff');
    font-weight: 600;
    font-style: normal;
  }
`
export default Fonts
