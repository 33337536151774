import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import useWindowSize from '../../helpers/utils/useWindowSize'
import { has, clone } from 'lodash'
import { sendEmail } from 'helpers/sendEmail'
import { SendEmailErrors } from 'types/mail/mailTypes'
import monkeyImg from 'img/form/monkey.png'
import btnBg from 'img/form/btn-bg.svg'
import btnBgMd from 'img/form/btn-bg-md.svg'
import closeImg from 'img/form/close.svg'
export interface ModalProps {
  onClose(): void
}

const DEFAULT_ERRORS_STATE: SendEmailErrors = {
  name: false,
  email: false,
  message: false,
}

interface ErrorProps {
  error?: boolean
}

const ContactForm: React.FunctionComponent<ModalProps> = (props) => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const [fieldErrors, setFieldErrors] = useState(DEFAULT_ERRORS_STATE)
  const [errorMessage, setErrorMessage] = useState('')
  const [isSending, setIsSending] = useState<boolean>(false)
  const [isSuccess, setIsSuccess] = useState<boolean>(false)
  const { width } = useWindowSize()

  const error = <Error>{errorMessage}</Error>

  const resetForm = () => {
    setMessage('')
    setName('')
    setEmail('')
    setFieldErrors(DEFAULT_ERRORS_STATE)
    setErrorMessage('')
  }

  const handleSubmit = async () => {
    setIsSending(true)
    const response = await sendEmail({ name, email, message })
    setIsSending(false)
    if (response.error) {
      console.log(response)
      setErrorMessage(response.error.details[0].message)
      const defaultState = clone(DEFAULT_ERRORS_STATE)
      const errorField: keyof SendEmailErrors =
        response.error.details[0].context.key
      if (has(fieldErrors, errorField)) {
        defaultState[errorField] = true
        setFieldErrors({ ...defaultState })
      }
      return
    }
    setIsSuccess(true)
    setTimeout(() => {
      setIsSuccess(false)
    }, 5000)
    resetForm()
  }

  return (
    <Form>
      {width <= 1260 && <Title>Contact Us</Title>}
      <Monkey src={monkeyImg} />
      <Row>
        <FieldsRow>
          <FieldsWrap>
            <Field error={fieldErrors.name}>
              {width <= 1260 && (
                <TopLine>
                  <Label>Name</Label>
                  {error}
                </TopLine>
              )}
              <Input
                type="text"
                value={name}
                onChange={(event) => setName(event.target.value)}
              />
            </Field>
            <Field error={fieldErrors.email}>
              {width <= 1260 && (
                <TopLine>
                  <Label>Email</Label>
                  {error}
                </TopLine>
              )}
              <Input
                type="email"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
              />
            </Field>
          </FieldsWrap>
          <Field error={fieldErrors.message}>
            {width <= 1260 && (
              <TopLine>
                <Label>Message</Label>
                {error}
              </TopLine>
            )}
            <TextArea
              value={message}
              onChange={(event) => setMessage(event.target.value)}
            />
          </Field>
        </FieldsRow>
        <Btn active={isSuccess} onClick={handleSubmit} disabled={isSending}>
          <BtnText>{isSuccess ? 'Message Sent' : 'Send Message'}</BtnText>
        </Btn>
      </Row>
      <Close onClick={props.onClose} />
    </Form>
  )
}

export default ContactForm

export interface SuccessProps {
  active?: boolean
}

const Form = styled.div`
  font-family: 'Sk-Modernist';
  position: relative;
  width: 1050px;
  height: 149px;
  background: #ffffff;
  box-shadow: 1px 10px 21px rgba(44, 140, 188, 0.17);
  border-radius: 9px 9px 0px 0px;
  padding: 30px 27px;
  margin-left: 660px;
  ${({ theme }) => theme.adaptive.main.xl} {
    width: 919px;
    height: 130px;
    border-radius: 8px 8px 0px 0px;
    padding: 26px 24px;
    margin-left: 580px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    width: 787px;
    height: 112px;
    border-radius: 7px 7px 0px 0px;
    padding: 23px 20px;
    margin-left: 500px;
  }
  ${({ theme }) => theme.adaptive.main.slg} {
    width: 679px;
    height: 96px;
    border-radius: 6px 6px 0px 0px;
    padding: 19px 17px;
    margin-left: 434px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    width: 386px;
    height: 646px;
    border-radius: 9px 9px 0px 0px;
    padding: 27px 31px;
    margin: 0 auto;
  }
  ${({ theme }) => theme.adaptive.main.smd} {
    width: 540px;
    height: 646px;
    border-radius: 9px 9px 0px 0px;
    padding: 27px 28px;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    width: 370px;
  }
  ${({ theme }) => theme.adaptive.main.xs} {
    width: 280px;
    height: 508px;
    border-radius: 7px 7px 0px 0px;
    padding: 20px 15px;
  }
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  ${({ theme }) => theme.adaptive.main.md} {
    display: block;
  }
`

const FieldsRow = styled.div`
  display: flex;
  ${({ theme }) => theme.adaptive.main.md} {
    display: block;
  }
`

const FieldsWrap = styled.div`
  height: 90px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 29px;
  ${({ theme }) => theme.adaptive.main.xl} {
    height: 80px;
    margin-right: 25px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    height: 67px;
    margin-right: 22px;
  }
  ${({ theme }) => theme.adaptive.main.slg} {
    height: 58px;
    margin-right: 19px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    display: block;
    height: auto;
    margin-right: 0;
  }
`

const TopLine = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 13px;
  ${({ theme }) => theme.adaptive.main.xs} {
    margin-bottom: 10px;
  }
`

const Label = styled.div`
  font-weight: 400;
  font-size: 13px;
  line-height: 11px;
  letter-spacing: 0.5px;
  text-transform: capitalize;
  color: #000000;
  ${({ theme }) => theme.adaptive.main.xs} {
    font-size: 12px;
    line-height: 9px;
  }
`

const Error = styled.div`
  font-weight: 400;
  font-size: 13px;
  line-height: 11px;
  letter-spacing: 0.5px;
  text-transform: capitalize;
  color: #ff2e2e;
  ${({ theme }) => theme.adaptive.main.xs} {
    font-size: 12px;
    line-height: 9px;
  }
`

const InputCss = css`
  width: 100%;
  background: rgba(187, 187, 187, 0.05);
  border: 1px solid #bbbbbb;
  border-radius: 5px;
  padding: 6px 17px;

  font-size: 17px;
  line-height: 24px;
  color: #2465bb;
  opacity: 0.75;
  transition: 0.4s;
  ${({ theme }) => theme.adaptive.main.xl} {
    border-radius: 4px;
    padding: 5px 15px;

    font-size: 15px;
    line-height: 21px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    border-radius: 4px;
    padding: 4px 13px;

    font-size: 13px;
    line-height: 18px;
  }
  ${({ theme }) => theme.adaptive.main.slg} {
    border-radius: 3px;
    padding: 3px 11px;

    font-size: 12px;
    line-height: 16px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    border-radius: 7px;
    padding: 15px 17px;

    font-size: 15px;
    line-height: 24px;
  }
  ${({ theme }) => theme.adaptive.main.smd} {
    border-radius: 9px;
    padding: 15px 17px;

    font-size: 17px;
    line-height: 24px;
  }
  ${({ theme }) => theme.adaptive.main.xs} {
    border-radius: 7px;
    padding: 13px 15px;

    font-size: 13px;
    line-height: 19px;
  }
`

const Input = styled.input`
  height: 37px;
  ${InputCss};
  ${({ theme }) => theme.adaptive.main.xl} {
    height: 32px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    height: 28px;
  }
  ${({ theme }) => theme.adaptive.main.slg} {
    height: 24px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    height: 55px;
  }
  ${({ theme }) => theme.adaptive.main.xs} {
    height: 43px;
  }
`

const TextArea = styled.textarea`
  height: 90px;
  ${InputCss};
  color: #000000;
  resize: none;
  ${({ theme }) => theme.adaptive.main.xl} {
    height: 80px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    height: 67px;
  }
  ${({ theme }) => theme.adaptive.main.slg} {
    height: 58px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    height: 199px;
  }
  ${({ theme }) => theme.adaptive.main.xs} {
    height: 157px;
  }
`

const Field = styled.div<ErrorProps>`
  position: relative;
  width: 299px;
  ${Input}, ${TextArea} {
    border: ${({ error }) =>
      error ? '1px solid #ff2e2e' : '1px solid #bbbbbb'};
  }
  ${Error} {
    opacity: ${({ error }) => (error ? '1' : 0)};
  }
  ${({ theme }) => theme.adaptive.main.xl} {
    width: 262px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    width: 222px;
  }
  ${({ theme }) => theme.adaptive.main.slg} {
    width: 191px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    width: 100%;
    margin-bottom: 29px;
  }
  ${({ theme }) => theme.adaptive.main.xs} {
    margin-bottom: 23px;
  }
`

const Monkey = styled.img`
  display: block;
  width: 195px;
  height: 195px;
  position: absolute;
  bottom: 0;
  left: 664px;
  ${({ theme }) => theme.adaptive.main.xl} {
    width: 171px;
    height: 171px;
    left: 581px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    width: 146px;
    height: 146px;
    left: 497px;
  }
  ${({ theme }) => theme.adaptive.main.slg} {
    width: 126px;
    height: 126px;
    left: 429px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    display: none;
  }
`

const BtnText = styled.div`
  font-weight: 400;
  font-size: 22px;
  line-height: 110%;
  letter-spacing: -0.04em;
  color: #ffffff;
  width: 93px;
  text-align: left;
  transition: 0.4s;
  ${({ theme }) => theme.adaptive.main.xl} {
    font-size: 20px;
    width: 81px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    font-size: 17px;
    width: 70px;
  }
  ${({ theme }) => theme.adaptive.main.slg} {
    font-size: 15px;
    width: 60px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    font-size: 22px;
    line-height: 100%;
    width: 139px;
    text-align: center;
  }
  ${({ theme }) => theme.adaptive.main.xs} {
    font-size: 17px;
    width: 108px;
  }
`

const Btn = styled.button<SuccessProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 134px;
  height: 90px;
  background: ${({ active }) =>
    active ? 'transparent' : css`url('${btnBg}') center no-repeat`};
  background-size: cover;
  transition: 0.4s;
  ${({ theme }) => theme.adaptive.main.xl} {
    width: 117px;
    height: 80px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    width: 100px;
    height: 67px;
  }
  ${({ theme }) => theme.adaptive.main.slg} {
    width: 87px;
    height: 58px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    width: 100%;
    height: 56px;
    border-radius: 7px;
    overflow: hidden;
    background: ${({ active }) =>
      active ? 'transparent' : css`url('${btnBgMd}') center no-repeat`};
    background-size: cover;
  }
  ${({ theme }) => theme.adaptive.main.xs} {
    height: 44px;
  }
  ${BtnText} {
    color: ${({ active }) => (active ? '#2465BB' : '#fff')};
  }
`

const Close = styled.button`
  position: absolute;
  top: -30px;
  right: -30px;
  width: 20px;
  height: 20px;
  background: url('${closeImg}') center no-repeat;
  background-size: cover;
  ${({ theme }) => theme.adaptive.main.xl} {
    top: -27px;
    right: -27px;
    width: 18px;
    height: 18px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    top: -22px;
    right: -22px;
    width: 15px;
    height: 15px;
  }
  ${({ theme }) => theme.adaptive.main.slg} {
    top: -19px;
    right: -19px;
    width: 13px;
    height: 13px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    top: 31px;
    right: 29px;
    width: 20px;
    height: 20px;
  }
  ${({ theme }) => theme.adaptive.main.smd} {
    right: 40px;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    right: 29px;
  }
  ${({ theme }) => theme.adaptive.main.xs} {
    top: 25px;
    right: 15px;
    width: 15px;
    height: 15px;
  }
`

const Title = styled.div`
  font-weight: 400;
  font-size: 22px;
  line-height: 26px;
  text-align: center;
  letter-spacing: -0.04em;
  color: #111212;
  margin-bottom: 34px;
  ${({ theme }) => theme.adaptive.main.xs} {
    font-size: 17px;
    line-height: 20px;
    margin-bottom: 27px;
  }
`
