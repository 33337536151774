import React from "react";
import styled from "styled-components";
import lineImg from "img/line-img.png";
import lineImgSmd from "img/line-img-smd.png";
import titleImg from "img/leftSide/title-img.png";
import monkeyBg from "img/leftSide/monkey-bg.png";
import monkeyImg from "img/leftSide/monkey.png";
import { ReactComponent as Arrow } from "img/leftSide/arrow.svg";
import { ReactComponent as ArrowSmd } from "img/leftSide/arrow-smd.svg";
import Socials from "./Socials";
import useWindowSize from "helpers/utils/useWindowSize";
import TitleBlock from "../RightSide/title";
import { DECK_LINK } from "configs/app";

const LeftSide: React.FunctionComponent = () => {
  const { width } = useWindowSize();

  const DeckButton = (
    <DeckWrap>
      {/*<HoverBg />*/}
      <DeckBtn href={DECK_LINK} target="_blank">
        <MonkeyBg>
          <BtnImg src={monkeyImg} />
        </MonkeyBg>
        <BtnTitleWrap>
          <BtnTitle>Deck</BtnTitle>
          <BtnSubTitle>DarkSide</BtnSubTitle>
        </BtnTitleWrap>
        <ArrowWrap>
          {width > 860 && <Arrow />}
          {width <= 860 && <ArrowSmd />}
        </ArrowWrap>
      </DeckBtn>
    </DeckWrap>
  );

  const SocialsButtons = (
    <SocialsWrap>
      <Socials />
    </SocialsWrap>
  );

  return (
    <Root>
      <TitleWrap>
        <ImgWrap>
          <TitleImg src={titleImg} />
        </ImgWrap>
        <TextWrap>
          <Title>DarkSide</Title>
          <SubTitle>Finance</SubTitle>
        </TextWrap>
      </TitleWrap>
      {width > 860 && DeckButton}
      {width > 860 && SocialsButtons}
      {width <= 860 && <TitleBlock />}
      {width <= 860 && (
        <BtnsRow>
          {DeckButton} {SocialsButtons}
        </BtnsRow>
      )}
      <LineWrap>
        <LineBlur />
        <Line />
      </LineWrap>
    </Root>
  );
};

export default LeftSide;

const Root = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${({ theme }) => theme.adaptive.main.smd} {
    padding-left: 0;
    padding-top: 56px;
  }
  ${({ theme }) => theme.adaptive.main.xs} {
    padding-top: 41px;
  }
`;

const LineWrap = styled.div`
  position: absolute;
  top: 50%;
  right: 61px;
  width: 244px;
  height: 100vh;
  transform: translateY(-50%);
  z-index: 2;
  ${({ theme }) => theme.adaptive.main.xl} {
    right: 56px;
    width: 213px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    right: 50px;
    width: 183px;
  }
  ${({ theme }) => theme.adaptive.main.slg} {
    right: 43px;
    width: 157px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    right: 37px;
    width: 119px;
    top: 0;
    transform: unset;
    height: 100%;
  }
  ${({ theme }) => theme.adaptive.main.smd} {
    right: unset;
    left: 50%;
    top: 0;
    width: 100vw;
    height: 23px;
    transform: translateX(-50%);
  }
`;

const Line = styled.div`
  width: 100%;
  height: 100%;
  background: url("${lineImg}") center no-repeat;
  background-size: cover;
  ${({ theme }) => theme.adaptive.main.smd} {
    background: url("${lineImgSmd}") repeat;
    background-size: cover;
  }
`;

const LineBlur = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("${lineImg}") center no-repeat;
  background-size: cover;
  opacity: 0.66;
  filter: blur(77px);
  transform: translate3d(0, 0, 0);
  ${({ theme }) => theme.adaptive.main.smd} {
    background: url("${lineImgSmd}") center no-repeat;
    background-size: cover;
    filter: blur(25px);
  }
`;

const TitleWrap = styled.div`
  display: flex;
  align-items: center;
  margin-top: 68px;
  ${({ theme }) => theme.adaptive.main.xl} {
    margin-top: 59px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    margin-top: 51px;
  }
  ${({ theme }) => theme.adaptive.main.slg} {
    margin-top: 44px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    margin-top: 51px;
  }
  ${({ theme }) => theme.adaptive.main.smd} {
    margin-top: 0;
  }
`;

const ImgWrap = styled.div`
  position: relative;
  margin-right: 15px;
  box-shadow: -8.19124px 0px 16.3825px -2.23397px rgba(210, 45, 65, 0.22);
  ${({ theme }) => theme.adaptive.main.xl} {
    margin-right: 12px;
    box-shadow: -7.16733px 0px 14.3347px -1.95473px rgba(210, 45, 65, 0.22);
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    margin-right: 10px;
    box-shadow: -6.14343px 0px 12.2869px -1.67548px rgba(210, 45, 65, 0.22);
  }
  ${({ theme }) => theme.adaptive.main.slg} {
    margin-right: 9px;
    box-shadow: -5.29017px 0px 10.5803px -1.44277px rgba(210, 45, 65, 0.22);
  }
  ${({ theme }) => theme.adaptive.main.md} {
    margin-right: 10px;
    box-shadow: -6.13278px 0px 12.2656px -1.67258px rgba(210, 45, 65, 0.22);
  }
  ${({ theme }) => theme.adaptive.main.smd} {
    margin-right: 14px;
    box-shadow: -8.07681px 0px 16.1536px -2.20277px rgba(210, 45, 65, 0.22);
  }
  ${({ theme }) => theme.adaptive.main.xs} {
    margin-right: 11px;
    box-shadow: -6.42586px 0px 12.8517px -1.75251px rgba(210, 45, 65, 0.22);
  }
  &:after {
    content: "";
    position: absolute;
    width: 7px;
    height: 37px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #ffffff;
    box-shadow: 0px 0px 4.21915px rgba(255, 255, 255, 0.4),
      0px 0px 15.8218px rgba(255, 255, 255, 0.55);
    border-radius: 1px;
    ${({ theme }) => theme.adaptive.main.xl} {
      width: 6px;
      height: 32px;
    }
    ${({ theme }) => theme.adaptive.main.lg} {
      width: 5px;
      height: 28px;
    }
    ${({ theme }) => theme.adaptive.main.slg} {
      width: 4px;
      height: 24px;
    }
    ${({ theme }) => theme.adaptive.main.md} {
      width: 5px;
      height: 28px;
    }
    ${({ theme }) => theme.adaptive.main.smd} {
      width: 7px;
      height: 37px;
    }
    ${({ theme }) => theme.adaptive.main.xs} {
      width: 5px;
      height: 28px;
    }
  }
`;

const TitleImg = styled.img`
  position: relative;
  display: block;
  width: 31px;
  height: 54px;
  ${({ theme }) => theme.adaptive.main.xl} {
    width: 27px;
    height: 47px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    width: 23px;
    height: 40px;
  }
  ${({ theme }) => theme.adaptive.main.slg} {
    width: 20px;
    height: 35px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    width: 23px;
    height: 40px;
  }
  ${({ theme }) => theme.adaptive.main.smd} {
    width: 31px;
    height: 54px;
  }
  ${({ theme }) => theme.adaptive.main.xs} {
    width: 24px;
    height: 42px;
  }
`;

const TextWrap = styled.div``;

const Title = styled.div`
  font-weight: 400;
  font-size: 26px;
  line-height: 140%;
  letter-spacing: -0.03em;
  color: #fafafa;
  ${({ theme }) => theme.adaptive.main.xl} {
    font-size: 23px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    font-size: 20px;
  }
  ${({ theme }) => theme.adaptive.main.slg} {
    font-size: 17px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    font-size: 20px;
  }
  ${({ theme }) => theme.adaptive.main.smd} {
    font-size: 26px;
  }
  ${({ theme }) => theme.adaptive.main.xs} {
    font-size: 20px;
  }
`;

const SubTitle = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 105%;
  letter-spacing: -0.04em;
  color: #be2840;
  ${({ theme }) => theme.adaptive.main.xl} {
    font-size: 14px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    font-size: 12px;
  }
  ${({ theme }) => theme.adaptive.main.slg} {
    font-size: 10px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    font-size: 12px;
  }
  ${({ theme }) => theme.adaptive.main.smd} {
    font-size: 16px;
  }
  ${({ theme }) => theme.adaptive.main.xs} {
    font-size: 13px;
  }
`;

const ArrowWrap = styled.div`
  width: 19px;
  height: 141px;
  margin: 24px auto 0;
  ${({ theme }) => theme.adaptive.main.xl} {
    width: 17px;
    height: 123px;
    margin: 22px auto 0;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    width: 14px;
    height: 106px;
    margin: 16px auto 0;
  }
  ${({ theme }) => theme.adaptive.main.slg} {
    width: 12px;
    height: 91px;
    margin: 12px auto 0;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    width: 14px;
    height: 106px;
    margin: 16px auto 0;
  }
  ${({ theme }) => theme.adaptive.main.smd} {
    width: 20px;
    height: 39px;
    margin: 0 0 0 125px;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    margin: 0 0 0 105px;
  }
  ${({ theme }) => theme.adaptive.main.xs} {
    width: 16px;
    height: 30px;
    margin: 0 0 0 83px;
  }
  svg {
    width: 100%;
    height: 100%;
    stroke: #b5182d;
    transition: 0.4s;
  }
`;

const BtnSubTitle = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  text-align: center;
  letter-spacing: -0.04em;
  color: #ffffff;
  opacity: 0.3;
  margin-top: 3px;
  transition: 0.4s;
  ${({ theme }) => theme.adaptive.main.xl} {
    font-size: 12px;
    margin-top: 2px;
  }
  ${({ theme }) => theme.adaptive.main.smd} {
    font-size: 14px;
    margin-top: 2px;
  }
  ${({ theme }) => theme.adaptive.main.xs} {
    font-size: 12px;
  }
`;

const BtnsRow = styled.div`
  display: block;
  ${({ theme }) => theme.adaptive.main.smd} {
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    display: block;
  }
  ${({ theme }) => theme.adaptive.main.xs} {
    margin-top: 48px;
  }
`;

const BtnTitle = styled.div`
  font-weight: 600;
  font-size: 28px;
  line-height: 34px;
  text-align: center;
  letter-spacing: -0.04em;
  color: #ffffff;
  margin-top: 23px;
  transition: 0.4s;
  ${({ theme }) => theme.adaptive.main.xl} {
    font-size: 25px;
    line-height: 30px;
    margin-top: 21px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    font-size: 22px;
    line-height: 26px;
    margin-top: 17px;
  }
  ${({ theme }) => theme.adaptive.main.slg} {
    font-size: 19px;
    line-height: 23px;
    margin-top: 15px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    font-size: 22px;
    line-height: 26px;
    margin-top: 17px;
  }
  ${({ theme }) => theme.adaptive.main.smd} {
    font-size: 28px;
    line-height: 34px;
    margin-top: 0;
  }
  ${({ theme }) => theme.adaptive.main.xs} {
    font-size: 22px;
    line-height: 26px;
  }
`;

const HoverBg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 9px;
  background: #b71a31;
  filter: blur(70px);
  opacity: 0;
  transition: 0.4s;
  ${({ theme }) => theme.adaptive.main.xl} {
    border-radius: 8px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    border-radius: 7px;
  }
  ${({ theme }) => theme.adaptive.main.slg} {
    border-radius: 6px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    border-radius: 7px;
  }
  ${({ theme }) => theme.adaptive.main.smd} {
    border-radius: 9px;
  }
  ${({ theme }) => theme.adaptive.main.xs} {
    border-radius: 7px;
  }
`;

const DeckBtn = styled.a`
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 9px;
  background: #1d1d1d;
  border: 0;
  outline: none;
  cursor: pointer;
  transition: 0.4s;
  ${({ theme }) => theme.adaptive.main.xl} {
    border-radius: 8px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    border-radius: 7px;
  }
  ${({ theme }) => theme.adaptive.main.slg} {
    border-radius: 6px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    border-radius: 7px;
  }
  ${({ theme }) => theme.adaptive.main.smd} {
    border-radius: 9px;
    display: flex;
    align-items: center;
  }
  ${({ theme }) => theme.adaptive.main.xs} {
    border-radius: 7px;
  }
`;

const DeckWrap = styled.div`
  position: relative;
  width: 118px;
  height: 388px;
  ${({ theme }) => theme.adaptive.main.xl} {
    width: 103px;
    height: 340px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    width: 89px;
    height: 291px;
  }
  ${({ theme }) => theme.adaptive.main.slg} {
    width: 76px;
    height: 251px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    width: 89px;
    height: 291px;
  }
  ${({ theme }) => theme.adaptive.main.smd} {
    width: 396px;
    height: 118px;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    width: 100%;
  }
  ${({ theme }) => theme.adaptive.main.xs} {
    height: 92px;
  }
  &:hover {
    ${HoverBg} {
      opacity: 0.4;
    }
    ${DeckBtn} {
      background: #0e0e0e;
    }
    &:hover {
      box-shadow: 0px 0px 80px 20px rgba(183, 26, 49, 0.2);
    }
  }
`;

const BtnTitleWrap = styled.div`
  ${({ theme }) => theme.adaptive.main.smd} {
    margin-left: 29px;
  }
  ${({ theme }) => theme.adaptive.main.xs} {
    margin-left: 18px;
  }
`;

const MonkeyBg = styled.div`
  position: relative;
  width: 100%;
  height: 117px;
  background: url("${monkeyBg}") center no-repeat;
  box-shadow: -8.19124px 0px 16.3825px -2.23397px rgba(210, 45, 65, 0.22);
  border-radius: 9px 9px 0 0;
  ${({ theme }) => theme.adaptive.main.xl} {
    height: 102px;
    box-shadow: -7.16733px 0px 14.3347px -1.95473px rgba(210, 45, 65, 0.22);
    border-radius: 8px 8px 0 0;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    height: 88px;
    box-shadow: -6.14343px 0px 12.2869px -1.67548px rgba(210, 45, 65, 0.22);
    border-radius: 7px 7px 0 0;
  }
  ${({ theme }) => theme.adaptive.main.slg} {
    height: 76px;
    box-shadow: -5.29017px 0px 10.5803px -1.44277px rgba(210, 45, 65, 0.22);
    border-radius: 6px 6px 0 0;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    height: 88px;
    box-shadow: -6.14343px 0px 12.2869px -1.67548px rgba(210, 45, 65, 0.22);
    border-radius: 7px 7px 0 0;
  }
  ${({ theme }) => theme.adaptive.main.smd} {
    width: 117px;
    height: 100%;
    box-shadow: -8.24513px 0px 16.4903px -2.24867px rgba(210, 45, 65, 0.22);
    border-radius: 9px 9px 0 0;
  }
  ${({ theme }) => theme.adaptive.main.xs} {
    width: 91px;
    box-shadow: -6.22274px 0px 12.4455px -1.69711px rgba(210, 45, 65, 0.22);
    border-radius: 7px 7px 0 0;
  }
`;

const BtnImg = styled.img`
  position: absolute;
  bottom: 0;
  left: 0;
  display: block;
  width: 152px;
  height: 154px;
  ${({ theme }) => theme.adaptive.main.xl} {
    width: 133px;
    height: 135px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    width: 114px;
    height: 116px;
  }
  ${({ theme }) => theme.adaptive.main.slg} {
    width: 98px;
    height: 99px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    width: 114px;
    height: 116px;
  }
  ${({ theme }) => theme.adaptive.main.smd} {
    width: 152px;
    height: 154px;
  }
  ${({ theme }) => theme.adaptive.main.xs} {
    width: 115px;
    height: 117px;
  }
`;

const SocialsWrap = styled.div`
  position: relative;
  width: 118px;
  height: 108px;
  background: #1d1d1d;
  border-radius: 9px 9px 0 0;
  ${({ theme }) => theme.adaptive.main.xl} {
    width: 103px;
    height: 95px;
    border-radius: 8px 8px 0 0;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    width: 89px;
    height: 81px;
    border-radius: 7px 7px 0 0;
  }
  ${({ theme }) => theme.adaptive.main.slg} {
    width: 76px;
    height: 70px;
    border-radius: 6px 6px 0 0;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    width: 89px;
    height: 81px;
    border-radius: 7px 7px 0 0;
  }
  ${({ theme }) => theme.adaptive.main.smd} {
    width: 121px;
    height: 118px;
    border-radius: 9px;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    width: 100%;
    height: 50px;
    border-radius: 9px;
    margin-top: 34px;
  }
  ${({ theme }) => theme.adaptive.main.xs} {
    height: 40px;
    border-radius: 7px;
    margin-top: 27px;
  }
`;
