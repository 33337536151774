import React, { useState } from "react";
import styled from "styled-components";
import { LeftContainer, RightContainer } from "theme/theme";
import LeftSide from "./LeftSide";
import bg from "img/bg.png";
import RightSide from "./RightSide";
import ContactForm from "components/modals/ContactForm";
import Noise from "components/common/Noise";

const Index: React.FunctionComponent = () => {
  const [formModalOpen, setFormModalOpen] = useState(false);

  const openModal = (): void => {
    setFormModalOpen(true);
  };
  return (
    <Root>
      <Noise />
      <LeftContainer>
        <LeftSide />
      </LeftContainer>
      <RightContainer>
        <ContactForm
          active={formModalOpen}
          onClose={() => setFormModalOpen(false)}
        />
        <RightSide openModal={openModal} />
      </RightContainer>
    </Root>
  );
};

export default Index;

const Root = styled.div`
  font-family: "Sk-Modernist";
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: url("${bg}") center no-repeat, #0d0c0c;
  background-size: cover;
  ${({ theme }) => theme.adaptive.main.md} {
    align-items: stretch;
    height: auto;
    min-height: 100%;
    overflow: auto;
  }
  ${({ theme }) => theme.adaptive.main.smd} {
    display: block;
    padding-bottom: 40px;
    overflow-x: hidden;
  }
`;
