import React from "react";
import styled from "styled-components";

interface cardProps {
  idx: number;
  card: {
    title: string;
    desc: string;
    img: string;
  };
}

const Card: React.FunctionComponent<cardProps> = (props) => {
  return (
    <Root red={props.idx === 0}>
      <CardTitle isRed={props.idx === 0}>{props.card.title}</CardTitle>
      <CardDesc>{props.card.desc}</CardDesc>
    </Root>
  );
};

export default Card;

const Root = styled.div<{ red: boolean }>`
  position: relative;
  width: 100%;
  height: 100%;
  padding: 30px;
  box-shadow: ${({ red }) =>
    red ? "0px 0px 80px 20px rgba(183, 26, 49, 0.2)" : ""};
  ${({ theme }) => theme.adaptive.main.xl} {
    padding: 20px 26px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    padding: 17px 20px;
  }
  ${({ theme }) => theme.adaptive.main.slg} {
    padding: 15px 19px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    padding: 10px 20px;
    box-shadow: 0px 0px 80px 20px rgba(183, 26, 49, 0.2);
  }
  ${({ theme }) => theme.adaptive.main.smd} {
    padding: 15px 25px;
  }
  ${({ theme }) => theme.adaptive.main.xs} {
    padding: 14px 23px;
  }
  &:hover {
    box-shadow: 0px 0px 100px 20px rgba(183, 26, 49, 0.2);
  }
`;

const CardTitle = styled.div<{ isRed?: boolean }>`
  position: relative;
  font-weight: 400;
  font-size: 26px;
  line-height: 31px;
  letter-spacing: -0.04em;
  color: ${({ isRed }) => (isRed ? "#D03048" : "#FFFFFF")};
  ${({ theme }) => theme.adaptive.main.xl} {
    font-size: 25px;
    line-height: 30px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    font-size: 22px;
    line-height: 26px;
  }
  ${({ theme }) => theme.adaptive.main.slg} {
    font-size: 19px;
    line-height: 23px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    font-size: 22px;
    line-height: 26px;
  }
  ${({ theme }) => theme.adaptive.main.smd} {
    font-size: 28px;
    line-height: 34px;
  }
  ${({ theme }) => theme.adaptive.main.xs} {
    font-size: 22px;
    line-height: 26px;
  }
`;

const CardDesc = styled.div`
  position: relative;
  font-weight: 400;
  font-size: 17px;
  line-height: 130%;
  letter-spacing: -0.04em;
  color: #ffffff;
  opacity: 0.5;
  margin-top: 12px;
  max-width: 255px;
  ${({ theme }) => theme.adaptive.main.xl} {
    font-size: 15px;
    margin-top: 11px;
    max-width: 229px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    font-size: 13px;
    margin-top: 10px;
    max-width: 196px;
  }
  ${({ theme }) => theme.adaptive.main.slg} {
    font-size: 12px;
    margin-top: 8px;
    max-width: 170px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    font-size: 13px;
    margin-top: 10px;
    max-width: 230px;
  }
  ${({ theme }) => theme.adaptive.main.smd} {
    font-size: 17px;
    margin-top: 14px;
    max-width: 324px;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    max-width: 275px;
  }
  ${({ theme }) => theme.adaptive.main.xs} {
    font-size: 13px;
    margin-top: 7px;
    max-width: 209px;
  }
`;
