import React from "react";
import styled from "styled-components";
import monkeyImg from "img/rightSide/monkey.png";
import monkeyBg from "img/leftSide/monkey-bg.png";
import cardsList from "../../../store/cards";
import FirstCardImg from "img/rightSide/cards/card-img-1.png";
import SecondCardImg from "img/rightSide/cards/card-img-2.png";
import ThirdCardImg from "img/rightSide/cards/card-img-3.png";
import FirstCardImgMd from "img/rightSide/cards/card-img-1-md.png";
import SecondCardImgMd from "img/rightSide/cards/card-img-2-md.png";
import ThirdCardImgMd from "img/rightSide/cards/card-img-3-md.png";
import FirstCardImgSmd from "img/rightSide/cards/card-img-1-smd.png";
import SecondCardImgSmd from "img/rightSide/cards/card-img-2-smd.png";
import ThirdCardImgSmd from "img/rightSide/cards/card-img-3-smd.png";
import FirstCardImgSm from "img/rightSide/cards/card-img-1-sm.png";
import SecondCardImgSm from "img/rightSide/cards/card-img-2-sm.png";
import ThirdCardImgSm from "img/rightSide/cards/card-img-3-sm.png";
import incubatedImg from "img/rightSide/incubated-img.svg";
import useWindowSize from "helpers/utils/useWindowSize";
import TitleBlock from "./title";
import Card from "./card";

interface modalProps {
  openModal: () => void;
}

const RightSide: React.FunctionComponent<modalProps> = (props) => {
  const { width } = useWindowSize();

  return (
    <Root>
      <Line />
      <DescWrap>
        <DeckImgWrap>
          <DescImg src={monkeyImg} />
        </DeckImgWrap>
        <TextWrap>
          <DescRow>
            <Border />
            <Desc>
              DarkSide Finance is a new breed of NFT-Fi, the NFT marketplace 2.0
              with purely DeFi mechanisms. We like to think of it as the NFT
              liquidity layer.
            </Desc>
          </DescRow>
          <Incubated href={"https://algodao.fi/"} target={"_blank"} />
        </TextWrap>
      </DescWrap>
      <Mid>
        {width > 860 && <TitleBlock />}
        <Cards>
          {cardsList.map((card, idx) => {
            return (
              <CardWrap key={idx}>
                <Card idx={idx} card={card} />
              </CardWrap>
            );
          })}
        </Cards>
      </Mid>
      <FooterWrap>
        {/* <Btn onClick={props.openModal}> */}
        <Btn href={"mailto: darkside@brightside.finance"} target="_blank">
          <BtnText>Contact Us</BtnText>
        </Btn>
        <CopyRight>© 2023 DarkSide. All Rights Reserved.</CopyRight>
      </FooterWrap>
    </Root>
  );
};

export default RightSide;

const Root = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 60px;
  ${({ theme }) => theme.adaptive.main.xl} {
    padding: 0 50px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    padding: 0 45px;
  }
  ${({ theme }) => theme.adaptive.main.slg} {
    padding: 0 40px;
  }
  ${({ theme }) => theme.adaptive.main.smd} {
    display: block;
    padding: 72px 0 0;
  }
  ${({ theme }) => theme.adaptive.main.xs} {
    padding: 52px 0 0;
  }
`;

const Mid = styled.div``;

const DescWrap = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 44px;
  ${({ theme }) => theme.adaptive.main.xl} {
    margin-top: 41px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    margin-top: 32px;
  }
  ${({ theme }) => theme.adaptive.main.slg} {
    margin-top: 28px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    margin-top: 44px;
  }
  ${({ theme }) => theme.adaptive.main.smd} {
    margin-top: 0;
    align-items: flex-start;
  }
`;

const DeckImgWrap = styled.div`
  position: relative;
  width: 123px;
  height: 123px;
  margin-right: 46px;
  box-shadow: -8.19124px 0px 16.3825px -2.23397px rgba(210, 45, 65, 0.22);
  background: url("${monkeyBg}") center no-repeat;
  background-size: cover;
  border-radius: 16px;
  ${({ theme }) => theme.adaptive.main.xl} {
    width: 108px;
    height: 108px;
    margin-right: 40px;
    box-shadow: -7.16733px 0px 14.3347px -1.95473px rgba(210, 45, 65, 0.22);
    border-radius: 14px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    width: 92px;
    height: 92px;
    margin-right: 35px;
    box-shadow: -6.14343px 0px 12.2869px -1.67548px rgba(210, 45, 65, 0.22);
    border-radius: 12px;
  }
  ${({ theme }) => theme.adaptive.main.slg} {
    width: 79px;
    height: 79px;
    margin-right: 30px;
    box-shadow: -5.29017px 0px 10.5803px -1.44277px rgba(210, 45, 65, 0.22);
    border-radius: 10px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    width: 94px;
    height: 94px;
    margin-right: 33px;
    box-shadow: -6.79273px 0px 13.5855px -1.85256px rgba(210, 45, 65, 0.22);
    border-radius: 13px;
  }
  ${({ theme }) => theme.adaptive.main.smd} {
    width: 117px;
    height: 117px;
    margin-right: 37px;
    box-shadow: -7.72506px 0px 15.4501px -2.10684px rgba(210, 45, 65, 0.22);
    border-radius: 15px;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    width: 92px;
    height: 92px;
    margin-right: 28px;
    box-shadow: -6.12678px 0px 12.2536px -1.67094px rgba(210, 45, 65, 0.22);
    border-radius: 12px;
  }
  ${({ theme }) => theme.adaptive.main.xs} {
    width: 70px;
    height: 70px;
    margin-right: 21px;
    box-shadow: -4.66168px 0px 9.32335px -1.27137px rgba(210, 45, 65, 0.22);
    border-radius: 9px;
  }
`;

const DescImg = styled.img`
  display: block;
  width: 100%;
  height: 100%;
`;

const TextWrap = styled.div``;

const DescRow = styled.div`
  display: flex;
  align-items: center;
  ${({ theme }) => theme.adaptive.main.md} {
    align-items: flex-start;
  }
`;

const Border = styled.div`
  width: 3px;
  height: 44px;
  background: linear-gradient(180deg, #cb2b48 0.01%, #61aac7 100.02%);
  border-radius: 12px;
  margin-right: 15px;
  ${({ theme }) => theme.adaptive.main.xl} {
    width: 3px;
    height: 39px;
    border-radius: 11px;
    margin-right: 13px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    width: 2px;
    height: 33px;
    border-radius: 9px;
    margin-right: 12px;
  }
  ${({ theme }) => theme.adaptive.main.slg} {
    width: 2px;
    height: 28px;
    border-radius: 8px;
    margin-right: 10px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    width: 2px;
    height: 30px;
    border-radius: 9px;
    margin-right: 12px;
  }
  ${({ theme }) => theme.adaptive.main.smd} {
    width: 3px;
    height: 37px;
    border-radius: 10px;
    margin-right: 13px;
  }
  ${({ theme }) => theme.adaptive.main.xs} {
    width: 2px;
    height: 28px;
    border-radius: 8px;
    margin-right: 10px;
  }
`;

const Desc = styled.div`
  font-weight: 400;
  font-size: 17px;
  line-height: 130%;
  letter-spacing: -0.04em;
  color: #9ea1a4;
  width: 646px;
  ${({ theme }) => theme.adaptive.main.xl} {
    font-size: 15px;
    width: 565px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    font-size: 13px;
    width: 499px;
  }
  ${({ theme }) => theme.adaptive.main.slg} {
    font-size: 12px;
    width: 459px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    font-size: 12px;
    width: 302px;
  }
  ${({ theme }) => theme.adaptive.main.smd} {
    font-size: 17px;
    width: 370px;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    font-size: 13px;
    width: 234px;
  }
  ${({ theme }) => theme.adaptive.main.xs} {
    width: 177px;
  }
`;

const Incubated = styled.a`
  position: relative;
  display: block;
  width: 169px;
  height: 50px;
  background: url("${incubatedImg}") center no-repeat;
  background-size: cover;
  margin-top: 16px;
  ${({ theme }) => theme.adaptive.main.xl} {
    width: 148px;
    height: 44px;
    margin-top: 14px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    width: 128px;
    height: 38px;
    margin-top: 12px;
  }
  ${({ theme }) => theme.adaptive.main.slg} {
    width: 116px;
    height: 34px;
    margin-top: 11px;
  }
  ${({ theme }) => theme.adaptive.main.smd} {
    width: 118px;
    height: 35px;
    margin-top: 19px;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    margin-top: 17px;
  }
  ${({ theme }) => theme.adaptive.main.xs} {
    width: 89px;
    height: 26px;
    margin-top: 11px;
  }
`;

const Cards = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 57px;
  ${({ theme }) => theme.adaptive.main.xl} {
    margin-top: 50px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    margin-top: 43px;
  }
  ${({ theme }) => theme.adaptive.main.slg} {
    margin-top: 37px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    display: block;
    margin-top: 59px;
  }
  ${({ theme }) => theme.adaptive.main.smd} {
    margin-top: 50px;
  }
  ${({ theme }) => theme.adaptive.main.xs} {
    margin-top: 33px;
  }
`;

const FooterWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${({ theme }) => theme.adaptive.main.md} {
    margin-top: 166px;
  }
  ${({ theme }) => theme.adaptive.main.smd} {
    margin-top: 79px;
  }
  ${({ theme }) => theme.adaptive.main.xs} {
    margin-top: 63px;
  }
`;

const BtnText = styled.div`
  font-weight: 400;
  font-size: 22px;
  text-align: center;
  letter-spacing: -0.04em;
  color: #ffffff;
  opacity: 0.4;
  transition: 0.4s;
  ${({ theme }) => theme.adaptive.main.xl} {
    font-size: 20px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    font-size: 16px;
  }
  ${({ theme }) => theme.adaptive.main.slg} {
    font-size: 14px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    font-size: 16px;
  }
  ${({ theme }) => theme.adaptive.main.smd} {
    font-size: 22px;
  }
  ${({ theme }) => theme.adaptive.main.xs} {
    font-size: 17px;
  }
`;

const Btn = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 326px;
  height: 64px;
  border-radius: 9px 9px 0 0;
  background: rgba(29, 29, 29, 0.9);
  transition: 0.4s;
  outline: none;
  box-shadow: 0px 0px 50px 15px rgba(183, 26, 49, 0.2);
  ${({ theme }) => theme.adaptive.main.xl} {
    width: 285px;
    height: 56px;
    border-radius: 8px 8px 0 0;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    width: 245px;
    height: 48px;
    border-radius: 7px 7px 0 0;
  }
  ${({ theme }) => theme.adaptive.main.slg} {
    width: 211px;
    height: 41px;
    border-radius: 6px 6px 0 0;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    width: 188px;
    height: 48px;
    border-radius: 7px 7px 0 0;
  }
  ${({ theme }) => theme.adaptive.main.smd} {
    width: 100%;
    height: 64px;
    border-radius: 9px;
  }
  ${({ theme }) => theme.adaptive.main.xs} {
    border-radius: 7px;
    height: 50px;
  }
  &:hover {
    ${BtnText} {
      color: #b5182d;
      opacity: 1;
    }
  }
`;

const CopyRight = styled.div`
  font-weight: 400;
  font-size: 17px;
  line-height: 140%;
  text-align: right;
  letter-spacing: -0.04em;
  color: #ffffff;
  opacity: 0.24;
  ${({ theme }) => theme.adaptive.main.xl} {
    font-size: 15px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    font-size: 13px;
  }
  ${({ theme }) => theme.adaptive.main.slg} {
    font-size: 12px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    font-size: 13px;
  }
  ${({ theme }) => theme.adaptive.main.smd} {
    display: none;
  }
`;

const Line = styled.div`
  position: absolute;
  top: 176px;
  right: 0;
  width: 100vw;
  height: 1px;
  background: #000000;
  opacity: 0.14;
  ${({ theme }) => theme.adaptive.main.xl} {
    top: 154px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    top: 132px;
  }
  ${({ theme }) => theme.adaptive.main.slg} {
    top: 114px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    top: 186px;
  }
  ${({ theme }) => theme.adaptive.main.smd} {
    display: none;
  }
`;

const CardWrap = styled.div`
  position: relative;
  width: 326px;
  height: 269px;
  border-radius: 9px;
  box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.07),
    0px 38.5185px 25.4815px rgba(0, 0, 0, 0.0425185),
    0px 8.14815px 6.51852px rgba(0, 0, 0, 0.0274815);
  background: url("${FirstCardImg}"),
    linear-gradient(180deg, #12181f 47.96%, rgba(18, 24, 31, 0) 100%);
  background-size: cover;
  transition: 0.4s;
  &:nth-child(2) {
    background: url("${SecondCardImg}"),
      linear-gradient(180deg, #12181f 47.96%, rgba(18, 24, 31, 0) 100%);
    background-size: cover;
  }
  &:nth-child(3) {
    background: url("${ThirdCardImg}"),
      linear-gradient(180deg, #12181f 47.96%, rgba(18, 24, 31, 0) 100%);
    background-size: cover;
  }
  ${({ theme }) => theme.adaptive.main.xl} {
    width: 285px;
    height: 235px;
    border-radius: 8px;
    box-shadow: 0px 87.5px 70px rgba(0, 0, 0, 0.07),
      0px 33.7037px 22.2963px rgba(0, 0, 0, 0.0425185),
      0px 7.12963px 5.7037px rgba(0, 0, 0, 0.0274815);
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    width: 244px;
    height: 202px;
    border-radius: 7px;
    box-shadow: 0px 75px 60px rgba(0, 0, 0, 0.07),
      0px 28.8889px 19.1111px rgba(0, 0, 0, 0.0425185),
      0px 6.11111px 4.88889px rgba(0, 0, 0, 0.0274815);
  }
  ${({ theme }) => theme.adaptive.main.slg} {
    width: 211px;
    height: 174px;
    border-radius: 6px;
    box-shadow: 0px 64.5833px 51.6667px rgba(0, 0, 0, 0.07), 0px 24.8765px 16.4568px rgba(0, 0, 0, 0.0425185), 0px 5.26235px 4.20988px rgba(0, 0, 0, 0.0274815);
  ${({ theme }) => theme.adaptive.main.md} {
    width: 386px;
    height: 125px;
    border-radius: 7px;
    box-shadow: 0px 64.5833px 51.6667px rgba(0, 0, 0, 0.07), 0px 24.8765px 16.4568px rgba(0, 0, 0, 0.0425185), 0px 5.26235px 4.20988px rgba(0, 0, 0, 0.0274815);
    background: url("${FirstCardImgMd}"),
    linear-gradient(180deg, #12181F 47.96%, rgba(18, 24, 31, 0) 100%)
        center no-repeat;
    background-size: cover;
    margin-bottom: 16px;
    &:nth-child(2) {
      background: url("${SecondCardImgMd}"),
      linear-gradient(180deg, #12181F 47.96%, rgba(18, 24, 31, 0) 100%)
          center no-repeat;
      background-size: cover;
    }
    &:nth-child(3) {
      background: url("${ThirdCardImgMd}"),
      linear-gradient(180deg, #12181F 47.96%, rgba(18, 24, 31, 0) 100%)
          center no-repeat;
      background-size: cover;
      margin-bottom: 0;
    }
  }
  ${({ theme }) => theme.adaptive.main.smd} {
    width: 540px;
    height: 150px;
    border-radius: 7px;
    margin-bottom: 20px;
    box-shadow:0px 90.5374px 72.4299px rgba(0, 0, 0, 0.07), 0px 34.8737px 23.0703px rgba(0, 0, 0, 0.0425185), 0px 7.37712px 5.9017px rgba(0, 0, 0, 0.0274815);
    background: url("${FirstCardImgSmd}"),
    linear-gradient(180deg, #12181F 47.96%, rgba(18, 24, 31, 0) 100%)
    center no-repeat;
    background-size: cover;
    &:nth-child(2) {
      background: url("${SecondCardImgSmd}"),
      linear-gradient(180deg, #12181F 47.96%, rgba(18, 24, 31, 0) 100%)
      center no-repeat;
      background-size: cover;
    }
    &:nth-child(3) {
      background: url("${ThirdCardImgSmd}"),
      linear-gradient(180deg, #12181F 47.96%, rgba(18, 24, 31, 0) 100%)
      center no-repeat;
      background-size: cover;
      margin-bottom: 0;
    }
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    width: 370px;
    background: url("${FirstCardImgSm}"),
    linear-gradient(180deg, #12181F 47.96%, rgba(18, 24, 31, 0) 100%)
        center no-repeat;
    background-size: cover;
    &:nth-child(2) {
      background: url("${SecondCardImgSm}"),
      linear-gradient(180deg, #12181F 47.96%, rgba(18, 24, 31, 0) 100%)
          center no-repeat;
      background-size: cover;
    }
    &:nth-child(3) {
      background: url("${ThirdCardImgSm}"),
      linear-gradient(180deg, #12181F 47.96%, rgba(18, 24, 31, 0) 100%)
          center no-repeat;
      background-size: cover;
    }
  }
  ${({ theme }) => theme.adaptive.main.xs} {
    width: 280px;
    height: 117px;
    margin-bottom: 16px;
    box-shadow: 0px 68.5497px 54.8398px rgba(0, 0, 0, 0.07), 0px 26.4043px 17.4675px rgba(0, 0, 0, 0.0425185), 0px 5.58553px 4.46843px rgba(0, 0, 0, 0.0274815);

    &:nth-child(3) {
      margin-bottom: 0;
    }
  }
`;
