import firstCardImg from "img/rightSide/cards/card-img-1.png";
import secondCardImg from "img/rightSide/cards/card-img-2.png";
import thirdCardImg from "img/rightSide/cards/card-img-3.png";

const cardsList = [
  {
    img: firstCardImg,
    title: "Liquid NFTs",
    desc: "Create whitelisted pools where users can deposit specific NFTs from a collection",
  },
  {
    img: secondCardImg,
    title: "NFT Index Tokens",
    desc: "Liquidity Providers deposit eligible NFTs inside pools gaining fungible 'index' tokens and stake them to earn $DARK.",
  },
  {
    img: thirdCardImg,
    title: "Yield Battles",
    desc: "Users can vote on the flow of $DARK inflation rewards, similar to Curve gauges, across all DARK pools.",
  },
];

export default cardsList;
