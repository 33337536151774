import React from 'react'
import styled from 'styled-components'
import { ReactComponent as Twitter } from 'img/socials/twitter.svg'
import { ReactComponent as Discord } from 'img/socials/discord.svg'
import { ReactComponent as Medium } from 'img/socials/medium.svg'
import { ReactComponent as Telegram } from 'img/socials/telegram.svg'
import {
  DISCORD_LINK,
  MEDIUM_LINK,
  TELEGRAM_LINK,
  TWITTER_LINK,
} from 'configs/app'

const Socials: React.FunctionComponent = () => {
  return (
    <Root>
      <Link href={TWITTER_LINK} target={'_blank'}>
        <Twitter />
      </Link>
      <Link href={DISCORD_LINK} target={'_blank'}>
        <Discord />
      </Link>
      <Link href={MEDIUM_LINK} target={'_blank'}>
        <Medium />
      </Link>
      <Link href={TELEGRAM_LINK} target={'_blank'}>
        <Telegram />
      </Link>
    </Root>
  )
}

export default Socials

const Root = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 12px 0;
  ${({ theme }) => theme.adaptive.main.xl} {
    padding: 11px 0;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    padding: 8px 0;
  }
  ${({ theme }) => theme.adaptive.main.slg} {
    padding: 7px 0;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    padding: 8px 0;
  }
  ${({ theme }) => theme.adaptive.main.smd} {
    padding: 12px 0;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    padding: 0;
    justify-content: space-around;
  }
`

const Link = styled.a`
  width: 22px;
  height: 22px;
  margin: 11px 12px;
  ${({ theme }) => theme.adaptive.main.xl} {
    width: 19px;
    height: 19px;
    margin: 10px 11px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    width: 16px;
    height: 16px;
    margin: 8px 9px;
  }
  ${({ theme }) => theme.adaptive.main.slg} {
    width: 14px;
    height: 14px;
    margin: 7px 8px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    width: 16px;
    height: 16px;
    margin: 8px 9px;
  }
  ${({ theme }) => theme.adaptive.main.smd} {
    width: 22px;
    height: 22px;
    margin: 11px 12px;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    margin: 0;
  }
  svg {
    width: 100%;
    height: 100%;
    transition: 0.4s;
    fill:  #B5182D;
  }
  &:hover {
    svg {
      fill: #2465BB;
    }
  }
`
