import Joi from 'joi'
import { SendEmailParams } from 'types/mail/mailTypes'

export const schema = Joi.object({
  name: Joi.string().min(2).max(40).required(),

  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required(),

  message: Joi.string().min(10).max(50000).required(),
})

export const validateEmail = (params: SendEmailParams) => {
  return schema.validate(params)
}
