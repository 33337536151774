import styled from 'styled-components'

const theme = {
  adaptive: {
    main: {
      xl: '@media screen and (max-width: 1820px), screen and (max-height: 830px)',
      lg: '@media screen and (max-width: 1590px), screen and (max-height: 750px)',
      slg: '@media screen and (max-width: 1360px), screen and (max-height: 635px)',
      md: '@media screen and (max-width: 1180px), screen and (max-height: 540px)',
      smd: '@media screen and (max-width: 860px)',
      sm: '@media screen and (max-width: 580px)',
      xs: '@media screen and (max-width: 420px)',
    },
  },
}

export const LeftContainer = styled.div`
  position: relative;
  width: 560px;
  height: 100%;
  ${({ theme }) => theme.adaptive.main.xl} {
    width: 492px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    width: 424px;
  }
  ${({ theme }) => theme.adaptive.main.slg} {
    width: 365px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    width: 311px;
    height: auto;
  }
  ${({ theme }) => theme.adaptive.main.smd} {
    width: 540px;

    margin: 0 auto;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    width: 370px;
  }
  ${({ theme }) => theme.adaptive.main.xs} {
    width: 280px;
  }
  &:before {
    content: '';
    position: absolute;
    width: 1px;
    height: 100vh;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    background: #000000;
    opacity: 0.14;
    ${({ theme }) => theme.adaptive.main.md} {
      top: 0;
      transform: unset;
      height: 100%;
    }
    ${({ theme }) => theme.adaptive.main.smd} {
      content: unset;
    }
  }
`

export const RightContainer = styled.div`
  position: relative;
  width: 1170px;
  height: 100%;
  ${({ theme }) => theme.adaptive.main.xl} {
    width: 1020px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    width: 875px;
  }
  ${({ theme }) => theme.adaptive.main.slg} {
    width: 755px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    width: 515px;
    height: auto;
  }
  ${({ theme }) => theme.adaptive.main.smd} {
    width: 540px;
    margin: 0 auto;
    height: auto;
    padding-bottom: 0;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    width: 370px;
  }
  ${({ theme }) => theme.adaptive.main.xs} {
    width: 280px;
  }
  &:before {
    content: '';
    position: absolute;
    width: 1px;
    height: 100vh;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    background: #000000;
    opacity: 0.14;
    ${({ theme }) => theme.adaptive.main.md} {
      top: 0;
      transform: unset;
      height: 100%;
    }
    ${({ theme }) => theme.adaptive.main.smd} {
      content: unset;
    }
  }
`

export default theme
