import React from "react";
import styled from "styled-components";
import bg from "img/rightSide/subtitle-bg.png";
import bgMd from "img/rightSide/subtitle-bg-md.png";
import planeImg from "img/rightSide/plane.png";
import planeBg from "img/rightSide/plane-bg.png";

const TitleBlock: React.FunctionComponent = () => {
  return (
    <TitleWrap>
      <PlaneBg />
      <Title>Explore the deep unknown</Title>
      <SubTitle>
        <SubtitleBg />
        of NFT liquidity
      </SubTitle>
      <Plane />
    </TitleWrap>
  );
};

export default TitleBlock;

const TitleWrap = styled.div`
  position: relative;
  font-family: "Sk-Modernist Mono";
  ${({ theme }) => theme.adaptive.main.md} {
    margin-top: 101px;
  }
  ${({ theme }) => theme.adaptive.main.smd} {
    margin-top: 38px;
  }
  ${({ theme }) => theme.adaptive.main.xs} {
    margin-top: 30px;
  }
`;

const Title = styled.div`
  position: relative;
  font-weight: 400;
  font-size: 72px;
  line-height: 105%;
  letter-spacing: -0.06em;
  color: #ffffff;
  ${({ theme }) => theme.adaptive.main.xl} {
    font-size: 63px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    font-size: 54px;
  }
  ${({ theme }) => theme.adaptive.main.slg} {
    font-size: 46px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    font-size: 54px;
  }
  ${({ theme }) => theme.adaptive.main.smd} {
    font-size: 41px;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    font-size: 36px;
  }
  ${({ theme }) => theme.adaptive.main.xs} {
    font-size: 27px;
  }
  span {
    margin-left: 15px;
    ${({ theme }) => theme.adaptive.main.slg} {
      margin-left: 10px;
    }
    ${({ theme }) => theme.adaptive.main.md} {
      margin-left: 0px;
      display: block;
    }
    ${({ theme }) => theme.adaptive.main.smd} {
      display: unset;
    }
  }
`;

const SubTitle = styled.div`
  position: relative;
  display: inline-block;
  font-size: 72px;
  line-height: 105%;
  letter-spacing: -0.06em;
  color: #fff;
  background: #be2840;
  border-radius: 9px;
  margin-top: 20px;
  padding: 0 20px 14px 20px;
  z-index: 2;
  ${({ theme }) => theme.adaptive.main.xl} {
    margin-top: 18px;
    font-size: 63px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    margin-top: 16px;
    font-size: 54px;
    padding: 0 19px 9px 19px;
  }
  ${({ theme }) => theme.adaptive.main.slg} {
    font-size: 46px;
    margin-top: 15px;
    padding: 0 16px 8px 16px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    margin-top: 25px;
    font-size: 54px;
    padding: 0 14px 8px 14px;
    max-width: 308px;
  }
  ${({ theme }) => theme.adaptive.main.smd} {
    font-size: 41px;
    margin-top: 15px;
    max-width: unset;
    padding: 0 13px 7px 13px;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    font-size: 40px;
  }
  ${({ theme }) => theme.adaptive.main.xs} {
    font-size: 28px;
    margin-top: 10px;
    padding: 0 10px 5px 10px;
  }
`;

const SubtitleBg = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 65px;
  opacity: 0.25;
  box-shadow: -15.5849px 0px 31.1698px -4.25043px rgba(210, 45, 65, 0.22);
  background: url("${bg}") center no-repeat;
  background-size: cover;
  z-index: -1;
  ${({ theme }) => theme.adaptive.main.xl} {
    height: 57px;
  }

  ${({ theme }) => theme.adaptive.main.lg} {
    height: 49px;
  }

  ${({ theme }) => theme.adaptive.main.slg} {
    height: 42px;
  }

  ${({ theme }) => theme.adaptive.main.md} {
    height: 97px;
    background: url("${bgMd}") center no-repeat;
    background-size: cover;
  }

  ${({ theme }) => theme.adaptive.main.smd} {
    background: url("${bg}") center no-repeat;
    background-size: cover;
    height: 35px;
  }

  ${({ theme }) => theme.adaptive.main.xs} {
    height: 26px;
  }
`;

const Plane = styled.div`
  position: absolute;
  left: 624px;
  top: 12px;
  width: 262px;
  height: 217px;
  background: url("${planeImg}") center no-repeat;
  background-size: cover;
  z-index: 2;
  ${({ theme }) => theme.adaptive.main.xl} {
    left: 550px;
    width: 230px;
    height: 190px;
  }

  ${({ theme }) => theme.adaptive.main.lg} {
    left: 475px;
    width: 196px;
    height: 163px;
  }

  ${({ theme }) => theme.adaptive.main.slg} {
    left: 405px;
    width: 170px;
    height: 140px;
  }

  ${({ theme }) => theme.adaptive.main.md} {
    display: none;
  }
`;

const PlaneBg = styled.div`
  position: absolute;
  left: 570px;
  top: -410px;
  width: 968px;
  height: 654px;
  background: url("${planeBg}") center no-repeat;
  background-size: cover;
  ${({ theme }) => theme.adaptive.main.xl} {
    left: 490px;
    top: -355px;
    width: 848px;
    height: 572px;
  }

  ${({ theme }) => theme.adaptive.main.lg} {
    left: 440px;
    top: -304px;
    width: 727px;
    height: 490px;
  }

  ${({ theme }) => theme.adaptive.main.slg} {
    left: 370px;
    top: -259px;
    width: 626px;
    height: 423px;
  }

  ${({ theme }) => theme.adaptive.main.md} {
    display: none;
  }
   {
  }
`;
